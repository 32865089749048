import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons"; //wiki加入IE判斷

import "../index.scss";
import * as Survey from "../Contexts/SurveyContext";
import * as Icon from "../Components/Icon";
import { Color, FontSize } from "../Components/Widget";
import constants from "../constants";
import Api from "../Api";

const queryString = require("query-string");

let windowGlobal = typeof window !== "undefined" && window;

const configs = {
  mainNavbarHeight: 64,
};

/**
 * 預覽頁面內頁 Header
 * @return {NavbarHeader}
 */

function NavbarHeader(props) {
  const { tab, mode, setTab, setLoad, extraCss = "" } = props;

  return (
    <HeaderBarWrapper extraCss={extraCss}>
      <Helmet>
        <title>中華電信CRM問卷平台</title>
      </Helmet>
      <div className="left">
        <div
          className="item large non-clickable highlight"
          style={{ fontSize: `${FontSize.subTitle}px` }}
        >
          預覽模式
        </div>
        {[
          { display: "問卷內容", key: "questions" },
          { display: "歡迎頁", key: "welcome" },
          { display: "結束頁", key: "end" },
          { display: "強制中止頁", key: "forceQuit" },
          { display: "額滿結束頁", key: "full" },
          { display: "部分額滿頁", key: "partiallyFull" },
          { display: "到期結束頁", key: "expired" },
          { display: "錯誤頁", key: "error" },
        ].map((item, idx) => (
          <div
            key={idx}
            className={`item${item.key === tab ? " highlight active" : ""}`}
            style={{ fontSize: FontSize.head }}
            onClick={() => {
              // if (top.location.toString().includes("&tab=end")) {
              //   var newLoc = top.location.toString().replace("&tab=end", "");
              //   top.location.replace(newLoc);
              // }
              setTab(item.key);
              setLoad(true);
            }}
          >
            {item.display}
          </div>
        ))}
      </div>

      <div className="right">
        <div
          className="item non-clickable highlight"
          style={{ fontSize: `${FontSize.head}px` }}
        >
          裝置
        </div>
        <div
          className={`item ${mode === "mobile" ? "highlight" : ""}`}
          onClick={() => props.setMode("mobile")}
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: `${FontSize.body}px`,
          }}
        >
          <Icon.Smartphone
            style={{ marginRight: 8 }}
            size={FontSize.title}
            color={
              mode === "mobile"
                ? Color.GreyWhite_85
                : "rgba(255, 255, 255, 0.25)"
            }
          />
          行動版
        </div>
        <div
          className={`item ${mode === "desktop" ? "highlight" : ""}`}
          onClick={() => props.setMode("desktop")}
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: `${FontSize.body}px`,
          }}
        >
          <Icon.DesktopMac
            style={{ marginRight: 8 }}
            size={FontSize.title}
            color={
              mode === "desktop"
                ? Color.GreyWhite_85
                : "rgba(255, 255, 255, 0.25)"
            }
          />
          網頁版
        </div>
        <div className="separate-line" />
        <div
          className="item large highlight"
          style={{ fontSize: `${FontSize.head}px` }}
          onClick={() => {
            window.close();
          }}
        >
          關閉預覽
        </div>
      </div>
    </HeaderBarWrapper>
  );
}

//wiki加入IE判斷
function _confirmIE() {
  Ant.Modal.warning({
    title: "本問卷不支援 IE 瀏覽器",
    content: "為提供最佳操作體驗，請改用 Chrome 瀏覽器。",
    okText: "關閉",
    onOk: () => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
  });
}

const HeaderBarWrapper = styled.div`
  height: ${configs.mainNavbarHeight}px;
  background-color: ${Color.Purple};
  font-size: ${FontSize.subTitle}px;
  color: rgba(255, 255, 255, 0.25);

  display: flex;
  justify-content: space-between;

  & > .left,
  & > .right {
    display: flex;
    align-items: stretch;

    & > .item.highlight {
      color: ${Color.GreyWhite};
    }

    & > .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding: 0px 17px;
    }

    & > .item.non-clickable {
      cursor: auto;
    }

    & > .item.active {
      &:after {
        position: absolute;
        content: "";
        display: block;
        background-color: ${Color.CyanCyan_2};
        width: 16px;
        height: 2px;
        transform: translateY(16px);
      }
    }
  }

  & > .left {
    & > .item.large {
      padding-left: 40px;
      padding-right: 28px;
    }
  }

  & > .right {
    & > .item.large {
      padding-left: 40px;
      padding-right: 40px;
    }

    & > .separate-line {
      height: 32px;
      width: 1px;
      background-color: ${Color.GreyWhite};
      align-self: center;
    }
  }

  ${props => props.extraCss}
`;

/**
 * 預覽頁面內頁
 * @return {PreviewQuestionnairePage}
 */

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "questions", // questions | end | forceQuit | full
      mode: "desktop", // 'mobile' or 'desktop'
      dimension: null,
      load: true,
      tokenValid: false,
    };
    this.loaded = this.loaded.bind(this);
    this.logout = this.logout.bind(this);
    this.tokenauth = this.tokenauth.bind(this);
  }

  componentDidMount() {
    //wiki加入IE判斷
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      _confirmIE();
      return;
    }
    this.setState({
      dimension: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
    this.setState({ tokenValid: false });
    let token = windowGlobal.localStorage.getItem("token");
    let profile = windowGlobal.localStorage.getItem("profile");

    async function validation() {
      let options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.token}`,
        },
      };

      try {
        const resp = await fetch(`${constants.apiUrl}/api/system/tokenAuth`, {
          ...options,
        });

        const json = await resp.json();
        return json;
      } catch (ex) {}
    }
    let resp;
    if (window.localStorage.token !== undefined) {
      resp = validation().then(resp => {
        if (resp.code && resp.code === "406") {
          this.setState(
            state => {
              return {
                tokenValid: false,
              };
            },
            () => {
              let hostname = window.location.host;
              // check host url to set the token Valid by state.
              //判斷是否在可運行的網址環境並確認token狀態
              if (
                hostname === "localhost:8000" || // 本地端環境
                hostname === "localhost:9000" || // 本地端環境
                hostname === "139.162.67.143:8080" || // 測試網址環境
                hostname === "ecrmsurvey.cht.com.tw" || // 中華正式環境
                hostname === "crmsurvey.cht.com.tw" // 中華正式環境
              ) {
                this.tokenauth();
              }
            }
          );
        } else if (resp.code && resp.code === "200") {
          this.tokenauth();
        }
      });
    } else {
      this.logout();
    }
    if (!profile || !token) {
      this.logout();
    }

    const showTab = window.localStorage.getItem("defaultTab");
    if (showTab === "end") {
      this.setState({ tab: showTab });
      window.localStorage.removeItem("defaultTab");
    }

    // const showTab = queryString.parse(this.props.location.search).tab;
    // if (showTab !== undefined) {
    //   this.setState({ tab: showTab });
    // }
  }

  componentDidUpdate(prevState) {
    // 常見用法（別忘了比較 prop）：
    let token = windowGlobal.localStorage.getItem("token");
    let profile = windowGlobal.localStorage.getItem("profile");

    async function validation() {
      let options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.token}`,
        },
      };

      try {
        const resp = await fetch(`${constants.apiUrl}/api/system/tokenAuth`, {
          ...options,
        });

        const json = await resp.json();
        return json;
      } catch (ex) {}
    }

    if (
      (this.state.mode !== prevState.mode ||
        this.state.tab !== prevState.tab) &&
      this.state.tokenValid
    ) {
      let resp;
      if (window.localStorage.token !== undefined) {
        resp = validation().then(resp => {
          if (resp.code === "406") {
            this.setState(
              state => {
                return {
                  tokenValid: false,
                };
              },
              () => {
                // check host url to set the token Valid by state.
                //判斷是否在可運行的網址環境並確認token狀態
                if (
                  hostname === "localhost:8000" || // 本地端環境
                  hostname === "localhost:9000" || // 本地端環境
                  hostname === "139.162.67.143:8080" || // 測試網址環境
                  hostname === "ecrmsurvey.cht.com.tw" || // 中華正式環境
                  hostname === "crmsurvey.cht.com.tw" // 中華正式環境
                ) {
                  this.tokenauth();
                }
              }
            );
          }
        });
      }

      if (!profile || !token) {
        this.logout();
      }
    }
  }

  tokenauth() {
    this.setState({ tokenValid: true });
  }

  logout() {
    Api.setToken(null);
    this.setState({ tokenValid: false });
    windowGlobal = typeof window !== "undefined" && window;
    windowGlobal.localStorage.removeItem("token");
    windowGlobal.localStorage.removeItem("profile");
    console.debug("navigate to root");
    navigate("/");
    //logout & return to login page
  }

  loaded() {
    this.setState({ load: false });
  }

  render() {
    const {
      pageContext: { preview },
    } = this.props;
    const { tab, mode, dimension, load, tokenValid } = this.state;

    let iframeHeight = null;
    if (typeof document !== `undefined`) {
      iframeHeight = document
        .getElementById("iframe-preview")
        ?.getBoundingClientRect().height;
    }

    if (tokenValid) {
      // 尺寸還沒設定就只出現 header
      if (!dimension) {
        return (
          <Survey.Provider>
            {preview && (
              <NavbarHeader
                {...this.props}
                tab={tab}
                mode={mode}
                setMode={mode => this.setState({ mode })}
                setTab={tab => this.setState({ tab })}
                setLoad={load => this.setState({ load })}
              />
            )}
          </Survey.Provider>
        );
      }

      const surveyId = queryString.parse(this.props.location.search).id;

      return (
        <Survey.Provider>
          {preview && (
            <NavbarHeader
              {...this.props}
              tab={tab}
              mode={mode}
              setMode={mode => this.setState({ mode })}
              setTab={tab => this.setState({ tab })}
              setLoad={load => this.setState({ load })}
            />
          )}
          {load && iframeHeight && (
            <SpinWrapper iframeHeight={iframeHeight}>
              <Ant.Spin />
            </SpinWrapper>
          )}
          <Wrapper className={mode}>
            {/* 2022-05-23 : 開啟預覽問卷的送出顯示結束頁面行為 */}
            {/* 改使用states控制iframe大小，並非重新render iframe */}
            <iframe
              id="iframe-preview"
              key="iframe"
              className={mode === "mobile" ? `mobile` : null}
              frameBorder="no"
              border="0"
              title={mode === "mobile" ? `mobile` : `Desktop`}
              onLoad={this.loaded}
              src={`/q?id=${surveyId}${this._getExtraQuestionnaireStatus(
                tab
              )}&preview=true`}
              width={mode === "mobile" ? 375 : "100%"}
              height={mode === "mobile" ? 735 : "100%"}
            />
          </Wrapper>
        </Survey.Provider>
      );
    } else {
      return <></>;
    }
  }

  _getExtraQuestionnaireStatus = tab => {
    const statusMap = {
      questions: "",
      end: "ending",
      forceQuit: "force_quit",
      full: "full",
      partiallyFull: "partially_full",
      expired: "expired",
      error: "error",
      welcome: "welcome",
    };
    if (tab === "questions" || !statusMap[tab]) {
      return "";
    } else {
      return `&status=${statusMap[tab]}`;
    }
  };
}

const SpinWrapper = styled.div`
  height: ${props => `${props.iframeHeight}px`};
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999999;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
  background-color: #f4faff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  iframe {
    border-radius: 5px;
    position: absolute;
    top: 0;
    border: 0;
  }
  &.desktop {
    iframe {
      width: 100vw;
      height: calc(100vh - 64px);
      border-radius: 0px;
      @media screen and (max-width: 823px) {
        width: 100%;
      }
    }
  }
  &.mobile {
    iframe {
      border-radius: 0px;
      margin: 0 auto;
    }
  }
`;

export default Page;
